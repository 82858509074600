<template>
<CCard :accent-color="estadoCreado">
    <CCardBody>
        <!--<CRow>
            <CCol md="12">
                <CAlert v-show="monstrarMensajeCreacion" :color="estadoMensajeCreacion">
                    {{mensajeCreacionUser}}
                </CAlert>
            </CCol>
        </CRow>-->
        <CRow>
            <CCol sm="3">
                Habilitado
            </CCol>
            <CCol sm="3">
                <CSwitch
                    width="150px"
                    class="mx-1 align-middle mb-2" 
                    color="success" 
                    shape="pill"
                    variant="outline"
                    v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                    :checked="usuario.habilitado"
                    @update:checked="usuario.habilitado = !usuario.habilitado"
                />
            </CCol>
        </CRow>
        <CInput
            v-model="usuario.userName"
            label="Nombre Usuario"
            type="text"
            horizontal
            placeholder="Nombre Usuario"
            @keydown="validarMaximo($event,usuario.userName,'userName')"
        />
        <CInput
            v-model="usuario.nombreUsuario"
            label="Nombres"
            type="text"
            horizontal
            placeholder="Nombre"
            @keydown="validarMaximo($event,usuario.nombreUsuario,'nombre')"
        />
        <CInput
            v-model="usuario.apellidoUsuario"
            label="Apellidos"
            type="text"
            horizontal
            placeholder="Apellido"
            @keydown="validarMaximo($event,usuario.apellidoUsuario,'apellido')"
        />
        <CInput
            v-model="usuario.rutUsuario"
            label="Rut"
            type="text"
            horizontal
            placeholder="12345678-9"
            :is-valid="usuario.rutValidadoUser"
            @blur="validarRut(usuario.rutUsuario, usuario)"
        />
        <CInput
            name="email"
            v-model="usuario.mailUsuario"
            label="Correo"
            type="text"
            horizontal
            placeholder="Correo"
            :is-valid="usuario.mailValidoUser"
            @blur="validarCampos(usuario)"
            @keydown="validarMaximo($event,usuario.mailUsuario,'mail')"
        />
    
        <!--<CRow class="mb-1">
            <CCol sm="3">
                <span class="m-auto">Asignar Perfil</span>
            </CCol>
            <CCol sm="5">
                <multiselect class="m-0 d-inline-block"
                    v-model="usuario.perfilAsignado"
                    deselect-label=""
                    label="nombrePerfil" 
                    placeholder="Perfil" 
                    select-label=""
                    selectedLabel=""
                    :custom-label="customNamePerfil"
                    :options="perfiles" 
                    :searchable="true"
                    :loading="isLoadingPerfiles"
                >
                </multiselect>
            </CCol>
        </CRow>-->
        <CRow>
            <CCol sm="3">
                Usuario Admin
            </CCol>
            <CCol sm="3">
                <CSwitch
                    width="150px"
                    class="mx-1 align-middle mb-2" 
                    color="success" 
                    shape="pill"
                    variant="outline"
                    v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                    :checked="usuario.usuarioAdmin"
                    @update:checked="usuario.usuarioAdmin = !usuario.usuarioAdmin; validarTipoUsuario('admin',usuario.usuarioAdmin)"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="3">
                Usuario Normal
            </CCol>
            <CCol sm="3">
                <CSwitch
                    width="150px"
                    class="mx-1 align-middle mb-2" 
                    color="success" 
                    shape="pill"
                    variant="outline"
                    v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                    :checked="usuario.usuarioNormal"
                    @update:checked="usuario.usuarioNormal = !usuario.usuarioNormal; validarTipoUsuario('normal',usuario.usuarioNormal)"
                />
                <!--@update:checked="(usuario.usuarioRevision)?usuario.usuarioRevision = !usuario.usuarioRevision:usuario.usuarioRevision=false"-->
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="3">
                Usuario Revisión
            </CCol>
            <CCol sm="6">
                <CSwitch
                    :disabled="!permiteUsuarioEmpresa"
                    width="150px"
                    class="mx-1 align-middle mb-2" 
                    color="success" 
                    shape="pill"
                    variant="outline"
                    v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                    :checked="usuario.usuarioRevision"
                    @update:checked="usuario.usuarioRevision = !usuario.usuarioRevision; validarTipoUsuario('revision',usuario.usuarioRevision)"
                /><span style="color:#E57373; font-size:14px;font-weight: bold;" v-show="!permiteUsuarioEmpresa"> *No tiene solicidato el módulo para usuarios de tipo revisión</span>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="3">
                Ver Sueldo Empresarial
            </CCol>
            <CCol sm="3">
                <CSwitch
                    width="150px"
                    class="mx-1 align-middle mb-2" 
                    color="success" 
                    shape="pill"
                    variant="outline"
                    v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                    :checked="usuario.sueldoEmpresarial"
                    @update:checked="usuario.sueldoEmpresarial = !usuario.sueldoEmpresarial"
                />
            </CCol>
        </CRow>
        <CRow v-show="!usuario.usuarioAdmin && !usuario.usuarioNormal && !usuario.usuarioRevision">
            <CCol sm="12">
                <span style="color:#E57373; font-size:14px;font-weight: bold;">*Al no seleccionar un tipo de usuario se guardará como "Usuario Normal"</span>
            </CCol>
        </CRow>
    </CCardBody>
    <CCardFooter>
        <CRow>
            <CCol sm="12">
                <CButton type="submit" class="mb-2 btn-class"  
                    @click="crearUsuario" :disabled="!usuario.rutValidadoUser 
                        || !usuario.mailValidoUser 
                        || !usuario.userName.length>0
                        || !usuario.nombreUsuario.length>0
                        || !usuario.apellidoUsuario.length>0
                        || isCreandoUser">
                    Crear Usuario
                </CButton>
            </CCol>
        </CRow>
    </CCardFooter>
</CCard>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    
    import cons from '@/const'
    import Multiselect from 'vue-multiselect'
    import axios from "axios"

    
    export default {
        name: 'CrearUsuario',
        props: {
            crudPrincipal: Object
        },
        components: {
            Multiselect
        },
        data () {
            return {
                urlBase: cons.port+"://" + cons.ipServer,
                //usuLogged = JSON.parse(this.$cookie.get('userLogginToken'))
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,

                regMail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
                
                isCreandoUser: false,
                isLoadingPerfiles: false,

                estadoCreado: "success",
                estadoMensajeCreacion: "danger",

                perfiles: [],
                permiteUsuarioEmpresa: false,

                //tiposUsuario: [{"nombre":"Admin"}, {"nombre":"Normal"}, {"nombre":"Revision"}],

                usuario: {
                    userName: "",
                    rutUsuario: "",
                    nombreUsuario: "",
                    apellidoUsuario: "",
                    mailUsuario: "",
                    perfilAsignado: null,
                    habilitado: true,
                    usuarioAdmin: false,
                    usuarioRevision: false,
                    usuarioNormal: true,
                    mailValidoUser: false,
                    rutValidadoUser: false,
                    sueldoEmpresarial: false,
                },

            }
        },
        created: function(){},
        beforeMount() {

            let cookieUsername = this.$cookie.get('userLogginToken')
            if(cookieUsername === null || cookieUsername === ""){
                this.$router.push("/pages/login");
            }

            this.validacionesCliente()
            //this.getPerfiles()
            
            

        },
        methods: {
            validacionesCliente: function(){
                var url = this.urlBase + "/get_validaciones_cliente"

                axios({
                    method: "POST", 
                    "url": url, 
                    "headers": {
                        'Authorization': `${this.tokenLogin}`,
                        "content-type": "application/json"
                    }
                }).then(result => {
                    this.permiteUsuarioEmpresa = result.data.permiteUsuarioEmpresa
                    

                }, error => {

                })
                .finally(() => {

                });

            },

            getPerfiles: function(){
                this.isLoadingPerfiles = true

                var url = cons.port+"://" + cons.ipServer + "/get_perfiles/"
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.perfiles = result.data;
                }, error => {
                    let mensaje = `Problema al obtener perfiles , ${error}`
                })
                .finally(() => {
                    this.isLoadingPerfiles = false
                });

            },
            cambiarPerfil: function(item){

                var encontrado = false
                this.usuariosCambiados.forEach(us =>{
                    if(us.idUsuario == item.idUsuario){
                        encontrado = true
                    }
                })
                if(!encontrado){
                    this.usuariosCambiados.push({
                        "idUsuario": item.idUsuario,
                        "idPerfil": 0
                    })
                }
            },
            
            crearUsuario: function(){

                this.isCreandoUser = true
                var url = cons.port+"://" + cons.ipServer + "/crear_usuario/"

                axios({
                    method: "POST", 
                    "url": url, 
                    "data": {"usuario": this.usuario},
                    "headers": {
                        'Authorization': `${this.tokenLogin}`,
                        "content-type": "application/json"
                    }
                }).then(result => {

                    this.estadoCreado = "success"
                    /*this.estadoMensajeCreacion = "success"
                    this.mensajeCreacionUser = result.data
                    this.monstrarMensajeCreacion = true*/
                    
                    this.$notification.success(result.data, {  timer: 10, position:"bottomRight"});

                    this.crudPrincipal.getUsuarios();
                    
                }, error => {

                    /*this.estadoMensajeCreacion = "danger"
                    this.mensajeCreacionUser = `Problema al obtener crear Usuario , ${error}`
                    this.monstrarMensajeCreacion = true*/
                    this.estadoCreado = "danger"
                    
                    
                    this.$notification.error(error.response.data, {  timer: 10, position:"bottomRight"});
                })
                .finally(() => {
                    this.isCreandoUser = false
                });

            },
            validarRut:function(rutCompleto,user){
                if(rutCompleto == "" || rutCompleto == null){
                    user.rutValidadoUser = false
                } else{

                    rutCompleto = rutCompleto.replace("‐","-");

                    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
                        return false;

                    var tmp     = rutCompleto.split('-');
                    var digv    = tmp[1]; 
                    var rut     = tmp[0];

                    if ( digv == 'K' ) digv = 'k' ;

                    user.rutValidadoUser = (this.validarDv(rut) == digv )
                }
                //return (Fn.dv(rut) == digv );
            },
            validarDv: function(T){
                var M=0,S=1;
                for(;T;T=Math.floor(T/10))
                    S=(S+T%10*(9-M++%6))%11;
                return S?S-1:'k';
            },
            validarMaximo: function(evt, texto, campo) {
                var limite = 10
                switch(campo){
                    case "userName":
                        limite = 20;
                        break;
                    case "nombre":
                        limite = 30
                        break;
                    case "apellido":
                        limite = 30
                        break;
                    case "mail":
                        limite = 50
                        break;
                }

                if (texto.length >= limite) {
                    if (evt.keyCode >= 48 && evt.keyCode <= 90) {
                        evt.preventDefault();
                        return;
                    }
                }
            },

            validarCampos: function(user){

                if(user.mailUsuario == "" || user.mailUsuario == null){
                    user.mailValidoUser = false
                } else{
                    user.mailValidoUser =  this.regMail.test(user.mailUsuario)
                }

            },

            validarTipoUsuario: function(tipoUsuario,valor){
                //if(usuario.)
                if(tipoUsuario == "normal"){
                    if(valor){
                        this.usuario.usuarioAdmin = false
                        this.usuario.usuarioRevision = false
                    }
                }
                if(tipoUsuario == "admin"){
                     if(valor){
                        this.usuario.usuarioNormal = false
                        this.usuario.usuarioRevision = false
                        //this.usuario.usuarioAdmin = true
                        //this.usuario.usuarioRevision = true
                     }
                }
                if(tipoUsuario == "revision"){
                    if(valor){
                        this.usuario.usuarioAdmin = false
                        this.usuario.usuarioNormal = false
                    }
                }

            },

            customNamePerfil(perfil){
                return `${perfil.nombrePerfil}`
            },

        }
        
    }
</script>