<template>
    <div>
        
        <CRow>
            <CCol md="12" >
                <CCard accent-color="info">
                    <CCardHeader>
                        <h4>Usuarios</h4>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol md="3">
                                <CButton size="sm" class="mb-2 btn-class" style="width:auto" @click="nuevoUsuario=!nuevoUsuario; monstrarMensajeCreacion = false;">
                                    <CIcon name="cil-user"/> {{(nuevoUsuario)?"Cerrar Creación":"Nuevo Usuario"}}
                                </CButton>
                            </CCol>
                        </CRow>
                        <CRow v-show="nuevoUsuario">
                            <CCol md="2"></CCol>
                            <CCol md="8">
                                <CrearUsuario :crudPrincipal="this" />
                            </CCol>
                            <CCol md="2"></CCol>
                        </CRow>
                        <!--<CRow>
                            <CCol md="12">
                                 <CAlert v-show="monstrarMensajeListaUsuarios" :color="estadoMensajeLista">
                                    {{mensajeListaUsuarios}}
                                </CAlert>
                            </CCol>
                        </CRow>-->
                        <CRow class="mb-2">
                            <CCol md="12">
                                <a-table
                                    :columns="columns" 
                                    :data-source="usuarios"
                                    :pagination="{ pageSize: 30 }"
                                    :scroll="{ y: 270 }"
                                    :locale="locale"
                                    :loading="isLoadingUsuarios"
                                    class="components-table-demo-nested m-1" 
                                    size="small" 
                                    style="overflow:auto;">
                                    <!--rutUsuario, userName, nombreUsuario, mailUsuario-->

                                    <span 
                                    slot="rutUsuario"
                                    slot-scope="record">
                                        <div v-show="!record.editar">
                                            {{record.userPropio?"(Yo)":""}} {{record.rutUsuario}}
                                        </div>
                                        
                                        <div v-show="record.editar">
                                            <CInput
                                                v-model="record.rutUsuario"
                                                type="text"
                                                horizontal
                                                placeholder="Rut Usuario"
                                                :is-valid="record.rutValidadoUser"
                                                @blur="validarRut(record.rutUsuario, record)"
                                            />
                                        </div>
                                    </span>

                                    <span 
                                    slot="userName"
                                    slot-scope="record">
                                        <div v-show="!record.editar">
                                            {{record.userName}}
                                        </div>
                                        <div v-show="record.editar">
                                            <CInput
                                                v-model="record.userName"
                                                type="text"
                                                horizontal
                                                placeholder="Nombre Usuario"
                                            />
                                        </div>
                                    </span>

                                    <span 
                                    slot="nombreUsuario"
                                    slot-scope="record">
                                        <div v-show="!record.editar">
                                            {{record.nombreUsuario}} {{record.apellidoUsuario}}
                                        </div>
                                        
                                        <div v-show="record.editar">
                                            <CCol>
                                                <CInput
                                                    v-model="record.nombreUsuario"
                                                    type="text"
                                                    horizontal
                                                    placeholder="Nombre"
                                                />
                                            </CCol>
                                            <CCol>
                                                <CInput
                                                    v-model="record.apellidoUsuario"
                                                    type="text"
                                                    horizontal
                                                    placeholder="Apellido"
                                                />
                                            </CCol>
                                        </div>
                                    </span>

                                    <span 
                                    slot="mailUsuario"
                                    slot-scope="record">
                                        <div v-show="!record.editar">
                                            {{record.mailUsuario}}
                                        </div>
                                        
                                        <div v-show="record.editar">
                                            <CInput
                                                v-model="record.mailUsuario"
                                                type="text"
                                                horizontal
                                                placeholder="Correo"
                                                :is-valid="record.mailValidoUser"
                                                @blur="validarCampos(record)"
                                                @keydown="validarMaximo($event,record.mailUsuario,'mail')"
                                            />
                                        </div>
                                    </span>

                                    <span
                                    slot="habilitado"
                                    slot-scope="record">
                                        <div v-show="!record.editar">
                                            {{record.habilitado?"Si":"No"}}
                                        </div>
                                        <div v-show="record.editar">
                                            <CSwitch
                                                width="150px"
                                                class="mx-1 align-middle mb-2" 
                                                color="success" 
                                                shape="pill"
                                                variant="outline"
                                                v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                :checked="record.habilitado"
                                                @update:checked="record.habilitado = !record.habilitado"
                                            />
                                        </div>
                                    </span>

                                    <span
                                    slot="tipoUser"
                                    slot-scope="record">
                                        <div v-show="!record.editar">
                                            {{(record.usuarioAdmin)?"Admin":(record.usuarioRevision?"Revisión":"Normal")}}
                                        </div>
                                        <div v-show="record.editar">
                                            <CRow>
                                                <CCol sm="5">
                                                    Admin
                                                </CCol>
                                                <CCol sm="3">
                                                    <CSwitch
                                                        width="150px"
                                                        class="mx-1 align-middle mb-2" 
                                                        color="success" 
                                                        shape="pill"
                                                        variant="outline"
                                                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                        :checked="record.usuarioAdmin"
                                                        @update:checked="record.usuarioAdmin = !record.usuarioAdmin; validarTipoUsuarioEdit('admin',record.usuarioAdmin,record)"
                                                    />
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol sm="5">
                                                    Normal
                                                </CCol>
                                                <CCol sm="3">
                                                    <CSwitch
                                                        width="150px"
                                                        class="mx-1 align-middle mb-2" 
                                                        color="success" 
                                                        shape="pill"
                                                        variant="outline"
                                                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                        :checked="record.usuarioNormal"
                                                        @update:checked="record.usuarioNormal = !record.usuarioNormal; validarTipoUsuarioEdit('normal',record.usuarioNormal,record)"
                                                    />
                                                    <!--@update:checked="(usuario.usuarioRevision)?usuario.usuarioRevision = !usuario.usuarioRevision:usuario.usuarioRevision=false"-->
                                                </CCol>
                                            </CRow>
                                            <CRow>
                                                <CCol sm="5">
                                                    Revision
                                                </CCol>
                                                <CCol sm="3">
                                                    <CSwitch
                                                        width="150px"
                                                        class="mx-1 align-middle mb-2" 
                                                        color="success" 
                                                        shape="pill"
                                                        variant="outline"
                                                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                        :checked="record.usuarioRevision"
                                                        @update:checked="record.usuarioRevision = !record.usuarioRevision; validarTipoUsuarioEdit('revision',record.usuarioRevision, record)"
                                                    />
                                                </CCol>
                                            </CRow>
                                        </div>

                                    </span>

                                    <span
                                    slot="acciones"
                                    slot-scope="record">
                                        <CButtonToolbar >
                                            <CAlert v-show="record.eliminar" color="danger">
                                                ¿Está seguro?
                                                <CButtonGroup class="mx-1">
                                                    <CButton color="success" size="sm"
                                                        v-c-tooltip.hover="{content:'Si, Eliminar', placement:'left'}"
                                                        @click="eliminarUser(record)">
                                                        <CIcon name="cil-check-circle"/><!-- Si!-->
                                                    </CButton>
                                                    <CButton color="danger" size="sm"
                                                        v-c-tooltip.hover="{content:'No!', placement:'right'}"
                                                        @click="record.eliminar = false">
                                                        <CIcon name="cil-x-circle"/><!-- No!-->
                                                    </CButton>
                                                </CButtonGroup>
                                            </CAlert>
                                            

                                            <CButtonGroup class="mx-1" v-show="record.editar && !record.eliminar">
                                                <CButton color="success" size="sm"
                                                    v-c-tooltip.hover="{content:'Aceptar Cambios', placement:'left'}"
                                                    :disabled="!record.rutValidadoUser 
                                                    || !record.mailValidoUser 
                                                    || !record.userName.length>0
                                                    || !record.nombreUsuario.length>0
                                                    || !record.apellidoUsuario.length>0"
                                                    @click="aceptarEdicion(record)">
                                                    <CIcon name="cil-check-circle"/><!-- Aceptar-->
                                                </CButton>
                                                <CButton color="danger" size="sm"
                                                    v-c-tooltip.hover="{content:'Cancelar Cambios', placement:'right'}"
                                                    @click="record.editar=false; cancelarEdicion(record)">
                                                    <CIcon name="cil-x-circle"/><!-- Cancelar-->
                                                </CButton>
                                            </CButtonGroup>

                                            <CButtonGroup size="sm" class="mx-1" v-show="!record.editar && !record.eliminar">
                                                <CButton color="warning" size="sm"
                                                v-c-tooltip.hover="{content:'Editar Usuario', placement:'left'}"
                                                @click="record.editar = true; editarUser(record)">
                                                    <CIcon name="cil-pencil"/><!-- Editar-->
                                                </CButton>
                                                <CButton color="danger" 
                                                v-c-tooltip.hover="{content:'Eliminar Usuario', placement:'right'}"
                                                @click="record.eliminar = true" size="sm" ><CIcon name="cil-x-circle"/><!-- Eliminar-->
                                                </CButton>
                                            </CButtonGroup>
                                            
                                        </CButtonToolbar>
                                    </span>
                                </a-table>
                            </CCol>
                        </CRow>
                    </CCardBody>
                    <CCardFooter>
                        <CButton type="submit" size="sm" class="float-right btn-class" v-show="userEditados.length>0"  @click="editarUsuarios">
                            <CIcon name="cil-check-circle"/> Guardar Cambios
                        </CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
        <CRow>
            
        </CRow>
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    
    import cons from '@/const'
    import Multiselect from 'vue-multiselect'
    import axios from "axios"
    import CrearUsuario from "./componentes/CrearUsuario"

    
    export default {
        name: 'UsuariosCrud',
        components: {
            CrearUsuario,
            Multiselect
        },
        data () {
            return {
                //usuLogged = JSON.parse(this.$cookie.get('userLogginToken'))
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,

                regMail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
                
                //estadoCreado: "success",
                monstrarMensajeCreacion: false,
                //mensajeCreacionUser: "",

                //estadoMensajeCreacion: "danger",

                mensajeListaUsuarios: "",
                estadoMensajeLista: "success",
                monstrarMensajeListaUsuarios: false,

                //isCreandoUser: false,
                isLoadingUsuarios: false,
                isEditanandoUsuario: false,
                isEliminandoUsuario: false,

                nuevoUsuario: false,
                userEditados: [],
                usuarios: [],
                
                /*usuario: {
                    userName: "",
                    rutUsuario: "",
                    nombreUsuario: "",
                    apellidoUsuario: "",
                    mailUsuario: "",
                    perfilAsignado: null,
                    habilitado: true,
                    usuarioAdmin: false,
                    usuarioRevision: false,
                    usuarioNormal: true,
                    mailValidoUser: false,
                    rutValidadoUser: false
                },*/

                //tiposUsuario: [{"nombre":"Admin"}, {"nombre":"Normal"}, {"nombre":"Revision"}],

                locale: {
                    filterConfirm: 'Filtrar',
                    filterReset: 'Limpiar',
                    emptyText: 'Seleccione sus opciones'
                },

                columns: [
                    { key: 'idUsuario', title: 'Rut', scopedSlots: { customRender: 'rutUsuario' }, align: 'center'},
                    { title: 'Nombre Usuario', scopedSlots: { customRender: 'userName' }, align: 'center'},
                    { title: 'Nombres/apellidos', scopedSlots: { customRender: 'nombreUsuario' }, align: 'center'},
                    { title: 'Correo', scopedSlots: { customRender: 'mailUsuario' }, align: 'center'},
                    { title: 'Habilitado', scopedSlots: { customRender: 'habilitado' }, align: 'center'},
                    { title: 'Tipo de Usuario', scopedSlots: { customRender: 'tipoUser' }, align: 'center'},
                    { title: 'Acciones', scopedSlots: { customRender: 'acciones' }, align: 'left'}
                ],

            }
        },
        created: function(){},
        beforeMount() {

            let cookieUsername = this.$cookie.get('userLogginToken')
            if(cookieUsername === null || cookieUsername === ""){
                this.$router.push("/pages/login");
            }
            this.getUsuarios()

        },
        methods: {
            /*mostrarNotificacion: function(){
                this.$notification.success("hello world", {  timer: 10, position:"bottomRight"});
                //this.$notification.error("hello world", { infiniteTimer: false, position:"bottomRight"});
                //createToast('Wow, easy')
            },*/
            editarUsuarios: function(){

                this.isEditanandoUsuario = true

                var url = cons.port+"://" + cons.ipServer + "/modificar_usuarios/"
                
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {"editados":this.userEditados},
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.estadoMensajeLista = "success"
                    this.mensajeListaUsuarios = result.data
                    this.monstrarMensajeListaUsuarios = true
                    this.getUsuarios()
                    this.userEditados = []
                    
                    this.$notification.success(result.data, {  timer: 10, position:"bottomRight"});

                }, error => {
                    this.mensajeListaUsuarios = `Problema al editar usuarios , ${error}`
                    this.estadoMensajeLista = "danger"
                    this.monstrarMensajeListaUsuarios = true

                    this.$notification.error(error.response.data, {  timer: 10, position:"bottomRight"});
                })
                .finally(() => {
                    this.isEditanandoUsuario = false
                });


            },
            eliminarUser: function(user){
                this.isEliminandoUsuario = true

                //let usuLogged = JSON.parse(this.$cookie.get('userLogginToken'))
                //var tokenLogin = usuLogged.tokenUsuario

                var url = cons.port+"://" + cons.ipServer + "/eliminar_usuario/"+user.idUsuario
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.getUsuarios()
                    
                    this.estadoMensajeLista = "success"
                    this.mensajeListaUsuarios = result.data
                    this.monstrarMensajeListaUsuarios = true
                    
                    var encontrado = this.userEditados.indexOf(this.userEditados.find(userf => userf.idUsuario == user.idUsuario))
                    if ( encontrado !== -1 ) {
                        this.userEditados.splice(encontrado, 1)
                    }
                    
                    this.$notification.success(result.data, {  timer: 10, position:"bottomRight"});

                }, error => {
                    this.mensajeListaUsuarios = `Problema al eliminar usuario , ${error}`
                    this.estadoMensajeLista = "danger"
                    this.monstrarMensajeListaUsuarios = true
                    
                    this.$notification.error(error.response.data, {  timer: 10, position:"bottomRight"});
                })
                .finally(() => {
                    this.isEliminandoUsuario = false
                });

            },
            aceptarEdicion: function(user){
                var encontrado = this.userEditados.find(userf => userf.idUsuario == user.idUsuario)

                if(!encontrado)
                    this.userEditados.push(user)

                user.editar = false


            },
            editarUser: function(user){

                user.respaldoUser = {
                    "idUsuario": user.idUsuario,
                    "userName": user.userName,
                    "rutUsuario": user.rutUsuario,
                    "nombreUsuario": user.nombreUsuario,
                    "apellidoUsuario": user.apellidoUsuario,
                    "habilitado": user.habilitado,
                    "idCliente": user.idCliente,
                    "usuarioAdmin": user.usuarioAdmin,
                    "usuarioRevision": user.usuarioRevision,
                    "usuarioNormal": user.usuarioNormal,
                    "mailUsuario": user.mailUsuario,
                    "editar": false
                }
            },
            cancelarEdicion: function(user){
                //user = user.respaldoUser
                //user.idUsuario = user.respaldoUser.idUsuario
                user.userName =  user.respaldoUser.userName
                user.rutUsuario = user.respaldoUser.rutUsuario
                user.nombreUsuario = user.respaldoUser.nombreUsuario
                user.apellidoUsuario = user.respaldoUser.apellidoUsuario
                user.habilitado = user.respaldoUser.habilitado
                user.idCliente = user.respaldoUser.idCliente
                user.usuarioAdmin = user.respaldoUser.usuarioAdmin
                user.usuarioRevision = user.respaldoUser.usuarioRevision
                user.usuarioNormal = user.respaldoUser.usuarioNormal
                user.mailUsuario = user.respaldoUser.mailUsuario
                user.respaldoUser = {}

                var encontrado = this.userEditados.indexOf(this.userEditados.find(userf => userf.idUsuario == user.idUsuario))
                if ( encontrado !== -1 ) {
                    this.userEditados.splice(encontrado, 1)
                }


                

            },
            getUsuarios: function(){
                this.isLoadingUsuarios = true

                //let usuLogged = JSON.parse(this.$cookie.get('userLogginToken'))
                //var tokenLogin = usuLogged.tokenUsuario

                var url = cons.port+"://" + cons.ipServer + "/get_usuarios_admin/"
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.usuarios = result.data;
                    if(this.usuarios.length == 0)
                        this.locale.emptyText = "Sin usuarios creados"
                }, error => {
                    this.textoSinUsuarios = `Problema al obtener empresas , ${error}`
                })
                .finally(() => {
                    this.isLoadingUsuarios = false
                });
            },

            validarCampos: function(user){
                if(user.mailUsuario == "" || user.mailUsuario == null){
                    user.mailValidoUser = false
                } else{
                    user.mailValidoUser =  this.regMail.test(user.mailUsuario)
                }

            },
            validarRut:function(rutCompleto,user){
                
                if(rutCompleto == "" || rutCompleto == null){
                    user.rutValidadoUser = false
                } else{

                    rutCompleto = rutCompleto.replace("‐","-");

                    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
                        return false;

                    var tmp = rutCompleto.split('-');
                    var digv = tmp[1]; 
                    var rut = tmp[0];

                    if ( digv == 'K' ) digv = 'k' ;

                    user.rutValidadoUser = (this.validarDv(rut) == digv )
                }
                
            },
            validarDv: function(T){
                var M=0,S=1;
                for(;T;T=Math.floor(T/10))
                    S=(S+T%10*(9-M++%6))%11;
                return S?S-1:'k';
            },

            /*crearUsuario: function(){

                this.isCreandoUser = true
                var url = cons.port+"://" + cons.ipServer + "/crear_usuario/"

                axios({
                    method: "POST", 
                    "url": url, 
                    "data": this.usuario,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`,
                        "content-type": "application/json"
                    }
                }).then(result => {

                    this.estadoCreado = "success"
                    this.estadoMensajeCreacion = "success"
                    this.mensajeCreacionUser = result.data
                    this.monstrarMensajeCreacion = true
                    
                    this.$notification.success(result.data, {  timer: 10, position:"bottomRight"});

                    this.getUsuarios()
                    
                }, error => {

                    this.estadoMensajeCreacion = "danger"
                    this.mensajeCreacionUser = `Problema al obtener crear Usuario , ${error}`
                    this.estadoCreado = "danger"
                    this.monstrarMensajeCreacion = true
                    
                    this.$notification.error(error.response.data, {  timer: 10, position:"bottomRight"});
                })
                .finally(() => {
                    this.isCreandoUser = false
                });

            },*/

            /*getPerfiles: function(){
                this.isLoadingPerfiles = true

                var url = cons.port+"://" + cons.ipServer + "/get_perfiles/"
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.perfiles = result.data;
                }, error => {
                    let mensaje = `Problema al obtener perfiles , ${error}`
                })
                .finally(() => {
                    this.isLoadingPerfiles = false
                });

            },¨*/
            validarTipoUsuarioEdit: function(tipoUsuario,valor,usuario){
                if(tipoUsuario == "normal"){
                    if(valor){
                        usuario.usuarioAdmin = false
                        usuario.usuarioRevision = false

                    }
                }
                if(tipoUsuario == "admin"){
                     if(valor){
                        usuario.usuarioNormal = false
                        usuario.usuarioRevision = false
                     }
                }
                if(tipoUsuario == "revision"){
                    if(valor){
                        usuario.usuarioAdmin = false
                        usuario.usuarioNormal = false
                    }
                }
            },
            validarMaximo: function(evt, texto, campo) {
                var limite = 10
                switch(campo){
                    case "userName":
                        limite = 20;
                        break;
                    case "nombre":
                        limite = 30
                        break;
                    case "apellido":
                        limite = 30
                        break;
                    case "mail":
                        limite = 50
                        break;
                }

                if (texto.length >= limite) {
                    if (evt.keyCode >= 48 && evt.keyCode <= 90) {
                        evt.preventDefault();
                        return;
                    }
                }
            },
        }
        
    }
</script>

<style>
.btn-class{
    background-color:#0b7689;
    color:white;
}
</style>